import { useMemo, useRef, useState } from "react";
import { Canvas } from "renderer/ExhibitRenderer";
import { SearchHuntMapDelegate } from "./SearchHuntMapWidget";
import { Box, Button, Container, createTheme, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { getDesignTokens } from "AppGlobals";

const SearchHuntStickyFooter: React.FC<{floorLevel: number, onChange: (floorNumber: number) => void}> = ({floorLevel, onChange}) => {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const theme = React.useMemo(() => createTheme(
        getDesignTokens(
        prefersDarkMode ? "dark" : "light")
        ),
        [
        prefersDarkMode ? "dark" : "light"
        ]
    );

    return (
        <Box
        sx={{
            width: '100%',
            position: 'fixed',
            bottom: 0,
            padding: '0.1rem',
            textAlign: 'center',
            boxShadow: '0 -2px 5px rgba(0,0,0,0.1)',
            backgroundColor: theme.palette.background.default,
        }}
        >
        <Container maxWidth="lg">
            <Grid container spacing={ 2 } alignItems="center" justifyContent="center">
                <Grid item>
                    <Typography variant="body1" color="textSecondary">
                        <Button variant="contained" onClick={ () => { onChange(0) } }>FLR 1</Button>
                        <Button variant="contained" onClick={ () => { onChange(1) } }>FLR 2</Button>
                        <Button variant="contained" onClick={ () => { onChange(2) } }>FLR 3</Button>
                        <Button variant="contained" onClick={ () => { onChange(3) } }>FLR 4</Button> 
                    </Typography>
                </Grid>
                <Grid item sx={{padding: '0' }} >
                    <Box sx={ {"display": "inline-block", "verticalAlign": "top", "fontSize": "8.0pt", "paddingRight": "5px"} }>powered by: </Box>
                    <Box sx={ {"display": "inline-block", "verticalAlign": "bottom"} }><a href="/"><img src="/logo256d.png" width="18px" /></a></Box>
                </Grid>
            </Grid>
        </Container>
        </Box>
    );
};

export const SearchHuntWidget = ({ exhibit_id }) => {
    const [floorLevel, setFloorLevel] = useState(0);
    const mapDelegate = new SearchHuntMapDelegate(floorLevel);

    return <>
        <div style={ { display: "fixed", width: "100%", height: "100%", margin: "0", userSelect: 'none', touchAction: 'none'}}>
            <Canvas renderer={ mapDelegate }></Canvas>
        </div>
        <SearchHuntStickyFooter floorLevel={floorLevel} onChange={ setFloorLevel } />
    </>;
}
