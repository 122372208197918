import { ZoomPanPinchControls } from "ZoomPanPinchControls";
import { MADECanvasRenderer } from "renderer/Renderer";
import * as THREE from 'three';
import { STARTING_CAMERA_POSITION } from "./constants";
import { SVGLoader } from "three/examples/jsm/loaders/SVGLoader";



class SearchHuntMapRenderer {
    canvas: HTMLCanvasElement
    renderer: THREE.WebGLRenderer
    scene: THREE.Scene
    camera: THREE.PerspectiveCamera
    controls: ZoomPanPinchControls
    floorLevel: number = 0
    svgLoader: SVGLoader = new SVGLoader();

    material:THREE.MeshBasicMaterial = new THREE.MeshBasicMaterial({
        color: "#aaaaaa",
        side: THREE.DoubleSide,
    });

    strokeMaterial:THREE.LineBasicMaterial = new THREE.LineBasicMaterial({
        color: "#000000",
    });

    svgPaths: string[] = [
        '/img/scavenger/opl_main_floor_1.svg',
        '/img/scavenger/opl_main_floor_2.svg',
        '/img/scavenger/opl_main_floor_3.svg',
        '/img/scavenger/opl_main_floor_4.svg',
    ];

    floorKeypoints: THREE.Vector3[][] = [
        [
            new THREE.Vector3(-0.75, -0.175, 0), // clue 3
        ],
        [
            new THREE.Vector3(-0.5, -0.5, 0),    // clue 1
            new THREE.Vector3(-0.05, -0.25, 0),  // clue 2
            new THREE.Vector3(-0.6, -0.01, 0),  // clue 4
            new THREE.Vector3(0.97, 0.68, 0),  // clue 8
            new THREE.Vector3(1.05, -0.3, 0),  // clue 9
            new THREE.Vector3(0.12, -0.77, 0),  // clue 10
            new THREE.Vector3(-0.3, -0.47, 0),  // clue 11
            new THREE.Vector3(0.65, -0.15, 0),  // clue 12
            new THREE.Vector3(0.65, -0.15, 0),  // clue 12b
            new THREE.Vector3(0.11, 0.3, 0),  // clue 14
        ],
        [
        ],
        [
            new THREE.Vector3(-0.3, -0.53, 0), // clue 5
            new THREE.Vector3(0.72, 0.17, 0),  // clue 6
            new THREE.Vector3(-0.6, 0.17, 0), // clue 7
            new THREE.Vector3(0.65, -.345, 0),  // clue 13
        ],
    ];

    loadFloor = async (floorLevel: number) => {
        this.floorLevel = floorLevel;
        
        this.scene.clear();

        var svgData = await this.svgLoader.loadAsync(
            this.svgPaths[this.floorLevel]
        );
        let floor:THREE.LineSegments[] = [];

        svgData.paths.forEach((path) => {
            const shapes = SVGLoader.createShapes(path);
            shapes.forEach((shape) => {
                shape.autoClose = false;
                const meshGeometry = new THREE.ExtrudeBufferGeometry(
                    shape,
                    {
                        depth: .01,
                        bevelEnabled: false,
                    }
                );

                const linesGeometry = new THREE.EdgesGeometry(meshGeometry);
                const lines = new THREE.LineSegments(
                    linesGeometry, 
                    this.strokeMaterial
                );
                lines.scale.x = 0.001;
                lines.scale.y = -0.001;
                lines.scale.z = 0.001;
                
                // lines.position.y = 1.0;
                // lines.position.x = -1.5;

                this.scene.add(lines);
            });
        });
    }

    addKeypoints = async () => {
        let f = this.floorKeypoints[this.floorLevel];
        let offset = new THREE.Vector3(1.5, -1.0, 0.0);

        // f.forEach((keypoint) => {
        //     const geometry = new THREE.PlaneGeometry(0.03, 0.03);
        //     const material = new THREE.MeshBasicMaterial({ color: 0xff0000 });
        //     const sphere = new THREE.Mesh(geometry, material);
        //     sphere.position.copy(keypoint.add(offset));
        //     this.scene.add(sphere);
        // });

        const geometry = new THREE.PlaneGeometry(0.03, 0.03);
        const material = new THREE.MeshBasicMaterial({ color: 0xff0000 });
        const mesh = new THREE.InstancedMesh(geometry, material, f.length);

        f.forEach((keypoint, index) => {
            const matrix = new THREE.Matrix4().makeTranslation(
            keypoint.x + offset.x,
            keypoint.y + offset.y,
            keypoint.z + offset.z
            );
            mesh.setMatrixAt(index, matrix);
        });

        this.scene.add(mesh);
    }

    init = async () => {
        this.renderer.setPixelRatio( window.devicePixelRatio );
        this.renderer.setSize( window.innerWidth, window.innerHeight );
        this.loadFloor(this.floorLevel);
        this.addKeypoints();

        this.camera.position.copy(new THREE.Vector3(1.5, -1, 5));

        this.controls.start();

    }

    animate = () => {
        requestAnimationFrame(() => this.animate());
        this.render();
    }

    render = () => {
        this.renderer.render( this.scene, this.camera );
    }

    constructor(canvas: HTMLCanvasElement, floorLevel: number) {
        this.floorLevel = floorLevel;
        this.canvas = canvas
        this.scene = new THREE.Scene();
        this.scene.background = new THREE.Color( 0xffffff );

        // Allow camera Z value to be very small with the map still visible
        this.camera = new THREE.PerspectiveCamera( 40, window.innerWidth / window.innerHeight, 0.0001, 100000 );
        this.renderer = new THREE.WebGLRenderer( { antialias: true, canvas: this.canvas } );
        this.controls = new ZoomPanPinchControls(this.renderer.domElement, this.camera.position);

        this.init();
        this.animate();

        window.addEventListener( 'resize', (event) => this.onWindowResize(event) );
    }


    onWindowResize(event) {
        event.preventDefault();
        document.getElementsByTagName("html")[0].style.overflow = "hidden";
        document.getElementsByTagName("html")[0].style.height = "100%";
        document.getElementById("app")!.style.height = "100%";
        this.renderer.setPixelRatio( window.devicePixelRatio );
        this.renderer.setSize( window.innerWidth, window.innerHeight );

        this.camera.aspect = window.innerWidth / window.innerHeight;
        this.camera.updateProjectionMatrix();
    }
}

export class SearchHuntMapDelegate implements MADECanvasRenderer {
    canvas?: HTMLCanvasElement
    renderer?: SearchHuntMapRenderer
    floor: number

    constructor(floor: number) {
        this.floor = floor;
    }

    start(canvas: HTMLCanvasElement) {
        this.canvas = canvas;
        this.renderer = new SearchHuntMapRenderer(canvas, this.floor);

        return true
    }

    async changeFloor(floor: number) {
        if (this.renderer != undefined) {
            await this.renderer.loadFloor(floor);
        }
    }

}
